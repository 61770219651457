import { Field, Form, Formik, useFormikContext } from 'formik'
import { isEmpty, some } from 'lodash'
import { useRouter } from 'next/router'
import React, { useContext } from 'react'
import * as Yup from 'yup'

import Button from 'src/components/Button'
import Checkbox from 'src/components/Checkbox'
import Disclaimer from 'src/components/Disclaimer'
import FormTextField from 'src/components/FormTextField'
import Title from 'src/components/Title'
import { AppCtx } from 'src/pages/_app'
import { LoginCtx } from 'src/pages/login'
import { tryAuthenticate } from 'src/services/authentication'
import { Login } from 'src/types/User'
import getErrorMessage from 'src/utils/getErrorMessage'
import { getNationalPhoneNumber } from 'src/utils/phoneNumber'

import styles from './TwoFactor.module.scss'

const TwoFactorForm = () => {
  const router = useRouter()
  const { twoFactorCredentials } = useContext(LoginCtx)
  const { message, setMessage } = useContext(AppCtx)

  const ResendCode = () => {
    const { setSubmitting, isSubmitting } = useFormikContext<Login>()

    const onResend = (method: string) => {
      setSubmitting(true)
      setMessage({})

      onAuthentication({
        ...twoFactorCredentials,
        ...(twoFactorCredentials.phoneNumber && {
          phoneNumber: getNationalPhoneNumber(
            twoFactorCredentials?.phoneNumber,
            twoFactorCredentials.phoneCountryCode,
          ),
          phoneCountryCode:
            twoFactorCredentials.phoneCountryCode?.toLowerCase(),
        }),
        twoFactorAuthenticationMethod: method,
      })
        .then(() => {
          const message: { call: string; sms: string } = {
            sms: 'An SMS code will be sent to your phone number with the authentication code.',
            call: 'You will be receiving a phone call soon with the authentication code.',
          }

          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          setMessage({ type: 'info', content: message[method] })
        })
        .catch(error => {
          setMessage({ type: 'error', content: getErrorMessage(error) })
        })
        .finally(() => setSubmitting(false))
    }

    return (
      <>
        <p
          className={styles.link}
          onClick={() => !isSubmitting && onResend('sms')}
        >
          Send code again
        </p>
        <p
          className={styles.link}
          onClick={() => !isSubmitting && onResend('call')}
        >
          Call my phone with the code
        </p>
      </>
    )
  }

  const onAuthentication = (params: Login) => {
    return tryAuthenticate({ ...params })
      .then(async response => {
        if (response?.user) {
          const { source } = router.query
          await router.push(
            source ? decodeURIComponent(source.toString()) : '/',
          )
        }
      })
      .catch(err => {
        throw err
      })
  }

  return (
    <div>
      <Title>Enter security code</Title>
      {!some(message, isEmpty) && !isEmpty(message) && (
        <Disclaimer type={message?.type}>{message?.content}</Disclaimer>
      )}
      <div className={styles.form}>
        <Formik
          initialValues={{ code: '', remember: false }}
          onSubmit={async (values, actions) => {
            try {
              await onAuthentication({
                ...twoFactorCredentials,
                ...(twoFactorCredentials.phoneNumber && {
                  phoneNumber: getNationalPhoneNumber(
                    twoFactorCredentials?.phoneNumber,
                    twoFactorCredentials.phoneCountryCode,
                  ),
                  phoneCountryCode:
                    twoFactorCredentials.phoneCountryCode?.toLowerCase(),
                }),
                twoFactorAuthenticationCode: values.code,
                twoFactorAuthenticationTrustPermanently: values.remember,
              })
            } catch (err) {
              actions.setSubmitting(false)
              setMessage({ type: 'error', content: getErrorMessage(err) })
            }
          }}
          validationSchema={Yup.object({
            code: Yup.string().required('Required field'),
            remember: Yup.bool(),
            // eslint-disable-next-line prettier/prettier
          })}
        >
          {({ isSubmitting }) => (
            <Form>
              <Field
                component={FormTextField}
                disabled={isSubmitting}
                hideErrorMessage
                id="code"
                label="The code is sent by SMS to your mobile phone"
                name="code"
              />
              <Field
                component={Checkbox}
                disabled={isSubmitting}
                id="remember"
                label="Remember this device"
                name="remember"
              />
              <p className={styles.checkboxInfo}>
                Checking this option means that you will no longer be required
                to enter an authentication code for this device.
              </p>
              <Button
                className={styles.button}
                disabled={isSubmitting}
                type="submit"
              >
                Continue
              </Button>
              <ResendCode />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default TwoFactorForm
