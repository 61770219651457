import { FieldProps } from 'formik'

import styles from './Checkbox.module.scss'

type CheckboxProps = {
  label?: string
} & FieldProps

const Checkbox = ({
  field,
  form: { touched, errors },
  label,
  ...props
}: CheckboxProps) => {
  return (
    <div className={styles.checkbox}>
      <label>
        <input type="checkbox" {...field} {...props} />
        <span className={styles.checkmark} />
        {touched[field.name] && errors[field.name] && (
          <span>{errors[field.name]}</span>
        )}
        {label}
      </label>
    </div>
  )
}

export default Checkbox
