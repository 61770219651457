/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import axios, { AxiosError } from 'axios'

const getErrorMessage = (error: AxiosError | any): string => {
  if (axios.isAxiosError(error)) {
    const {
      response: {
        data: {
          error: { message },
        },
      },
    } = error

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return message
  }

  return String(error)
}

export default getErrorMessage
