import classNames from 'classnames'
import { Field, Form, Formik } from 'formik'
import { isEmpty, some } from 'lodash'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useContext, useMemo } from 'react'
import * as Yup from 'yup'

import Button from 'src/components/Button'
import Disclaimer from 'src/components/Disclaimer'
import FormTextField from 'src/components/FormTextField'
import PhoneNumberField from 'src/components/PhoneNumberField'
import Title from 'src/components/Title'
import { AppCtx } from 'src/pages/_app'
import { LoginCtx } from 'src/pages/login'
import { tryAuthenticate } from 'src/services/authentication'
import getErrorMessage from 'src/utils/getErrorMessage'
import {
  getNationalPhoneNumber,
  getPhoneNumberObject,
  phoneNumberSchema,
} from 'src/utils/phoneNumber'

import styles from './InitialForm.module.scss'

const InitialForm = () => {
  const router = useRouter()
  const { query } = router
  const { setMessage, message } = useContext(AppCtx)
  const { setTwoFactorCredentials } = useContext(LoginCtx)
  const loginType = useMemo(() => query?.type || 'phoneNumber', [query])

  return (
    <div className={styles.initialForm}>
      <Title>Log in</Title>
      {!some(message, isEmpty) && !isEmpty(message) && (
        <Disclaimer type={message?.type}>{message?.content}</Disclaimer>
      )}
      <div className={styles.form}>
        <Formik
          initialValues={{
            ...(loginType === 'email' && { email: query?.email?.toString() }),
            ...(loginType !== 'email' && {
              phoneNumber: query?.phoneNumber?.toString(),
            }),
            ...(loginType !== 'email' && {
              phoneCountryCode:
                getPhoneNumberObject(
                  query?.phoneNumber?.toString(),
                )?.country?.toLowerCase() || 'US',
            }),
            password: '',
          }}
          onSubmit={(values, actions) => {
            setMessage({})

            tryAuthenticate({
              ...values,
              ...(values.phoneNumber && {
                phoneNumber: getNationalPhoneNumber(
                  values.phoneNumber,
                  values.phoneCountryCode,
                ),
                phoneCountryCode: values.phoneCountryCode?.toLowerCase(),
              }),
            })
              .then(async response => {
                if (response?.twoFactorAuthentication) {
                  setTwoFactorCredentials(values)
                  await router.push('/login?step=twoFactor')
                } else if (response?.user) {
                  const { source } = query

                  await router.push(
                    source
                      ? decodeURIComponent(source.toString())
                      : '/properties',
                  )
                }
              })
              .catch(err => {
                setMessage({ type: 'error', content: getErrorMessage(err) })
              })
              .finally(() => actions.setSubmitting(false))
          }}
          validationSchema={Yup.object({
            password: Yup.string().required('Required field'),
            ...(loginType === 'email' && {
              email: Yup.string().email().required('Required field'),
            }),
            ...(loginType !== 'email' && {
              phoneNumber: phoneNumberSchema(),
            }),
            // eslint-disable-next-line prettier/prettier
          })}
        >
          {({ isSubmitting }) => (
            <>
              <Form>
                <div className={styles.formContent}>
                  {loginType === 'email' && (
                    <Field
                      component={FormTextField}
                      disabled={isSubmitting}
                      id="email"
                      label="Email"
                      name="email"
                    />
                  )}
                  {loginType !== 'email' && (
                    <Field
                      component={PhoneNumberField}
                      disabled={isSubmitting}
                      id="phoneNumber"
                      label="Phone number"
                      name="phoneNumber"
                    />
                  )}
                  <Field
                    component={FormTextField}
                    disabled={isSubmitting}
                    id="password"
                    label="Password"
                    name="password"
                    type="password"
                  />
                  <Button disabled={isSubmitting} type="submit">
                    log in
                  </Button>
                </div>
              </Form>
              <div className={styles.link}>
                <Link href="/forgot-password">
                  <a
                    className={classNames({ [styles.disabled]: isSubmitting })}
                  >
                    Forgot password?
                  </a>
                </Link>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default InitialForm
