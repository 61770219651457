import { isEmpty, some } from 'lodash'
import { useRouter } from 'next/router'
import React, { createContext, useContext, useEffect, useState } from 'react'

import InitialForm from 'src/components/Forms/Login/InitialForm'
import TwoFactorForm from 'src/components/Forms/Login/TwoFactorForm'
import Layout from 'src/components/Layout'
import { withAuthServerSideProps } from 'src/hoc/withAuth'
import { AppCtx } from 'src/pages/_app'
import { Login } from 'src/types/User'

export type LoginContext = {
  setTwoFactorCredentials: React.Dispatch<React.SetStateAction<Login>>
  twoFactorCredentials: Login
}

const DEFAULT_VALUE = {
  twoFactorCredentials: { email: '', password: '' },
  setTwoFactorCredentials: () => null,
  step: 'initial',
}

export const LoginCtx = createContext<LoginContext>(DEFAULT_VALUE)

const Login = () => {
  const { query } = useRouter()
  const { setMessage } = useContext(AppCtx)
  const [step, setStep] = useState<string>(DEFAULT_VALUE.step)
  const [twoFactorCredentials, setTwoFactorCredentials] = useState<Login>(
    DEFAULT_VALUE.twoFactorCredentials,
  )

  useEffect(() => {
    if (query.step === 'twoFactor' && !some(twoFactorCredentials, isEmpty)) {
      setStep('twoFactor')
    } else {
      setStep('initial')
    }

    setMessage({})
  }, [query, twoFactorCredentials, setMessage])

  return (
    <LoginCtx.Provider
      value={{
        twoFactorCredentials,
        setTwoFactorCredentials,
        // eslint-disable-next-line prettier/prettier
      }}
    >
      <Layout backButton backState="/" title="Login | Citifyd Enforcement App">
        {step !== 'twoFactor' && <InitialForm />}
        {step === 'twoFactor' && <TwoFactorForm />}
      </Layout>
    </LoginCtx.Provider>
  )
}

export const getServerSideProps = withAuthServerSideProps()

export default Login
