/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { isValidPhoneNumber } from 'react-phone-number-input'
import * as Yup from 'yup'

export const phoneNumberSchema = () =>
  Yup.string().test({
    name: 'phoneNumber',
    message: 'Invalid phone number',
    test: value => {
      return value?.length > 3 && isValidPhoneNumber(value)
    },
  })

export const getNationalPhoneNumber = (phoneNumber: string, countryCode) => {
  if (!phoneNumber || !countryCode) {
    return ''
  }

  const number = parsePhoneNumberFromString(phoneNumber, countryCode)

  return number ? number.nationalNumber : ''
}

export const getPhoneNumberObject = (phoneNumber: string) => {
  return phoneNumber && parsePhoneNumberFromString(phoneNumber)
}

export const getInternationalPhoneNumber = (
  phoneNumber: string,
  countryCode,
) => {
  if (!phoneNumber || !countryCode) {
    return ''
  }

  const number = parsePhoneNumberFromString(phoneNumber, countryCode)

  return number ? number.number : ''
}
