import classNames from 'classnames'
import React, { ReactNode } from 'react'

import styles from './Disclaimer.module.scss'

type DisclaimerProps = {
  children: ReactNode
  className?: string
  type?: string
}

const Disclaimer = ({
  children,
  className,
  type = 'info',
}: DisclaimerProps) => {
  return (
    <div
      className={classNames(styles.disclaimer, className, {
        [styles.error]: type === 'error',
      })}
    >
      {children}
    </div>
  )
}

export default Disclaimer
