import classNames from 'classnames'
import React, { ReactNode } from 'react'

import styles from './Title.module.scss'

const Title = ({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) => {
  return <h1 className={classNames(styles.title, className)}>{children}</h1>
}

export default Title
