import { isEmpty, some } from 'lodash'
import React, { useContext, useMemo } from 'react'

import TextField from 'src/components/TextField'
import { AppCtx } from 'src/pages/_app'
import { FormTextFieldProps } from 'src/types/Form'

import styles from './FormTextField.module.scss'

const FormTextField: React.FunctionComponent<FormTextFieldProps> = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  className,
  children,
  component: Component,
  onChange,
  hideErrorMessage = false,
  ...props
}: FormTextFieldProps) => {
  const { label, onKeyUp } = props
  const { setMessage, message } = useContext(AppCtx)

  const hasError = useMemo(
    () => touched[field.name] && errors[field.name],
    [touched, errors, field.name],
  )

  const onValueChange = (value: string) => {
    if (onChange) {
      onChange(value)
    }

    void setFieldValue(field.name, value)
  }

  const onBlur = () => {
    void setFieldTouched(field.name, true)
  }

  const clearMessage = () =>
    !some(message, isEmpty) && !isEmpty(message) && setMessage({})

  return (
    <TextField
      className={className}
      renderInput={
        <>
          {children}
          {Component && (
            <Component
              {...props}
              {...field}
              name={field.name}
              onBlur={onBlur}
              onChange={onValueChange}
              onKeyUp={e => {
                clearMessage()
                onKeyUp && onKeyUp(e)
              }}
            />
          )}
          {!Component && (
            <input
              {...field}
              {...props}
              onKeyUp={e => {
                clearMessage()
                onKeyUp && onKeyUp(e)
              }}
            />
          )}

          {hasError && <p className={styles.errorIcon}>!</p>}
        </>
      }
      renderLabel={
        <>
          <label htmlFor={field.name}>{label}</label>
          {!hideErrorMessage && hasError && <p>{errors[field.name]}</p>}
        </>
      }
    />
  )
}

export default FormTextField
