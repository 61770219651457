import classNames from 'classnames'
import React from 'react'

import { TextFieldProps } from 'src/types/Form'

import styles from './TextField.module.scss'

const TextField: React.FunctionComponent<TextFieldProps> = ({
  renderInput,
  renderLabel,
  className,
}: TextFieldProps) => {
  return (
    <div className={classNames(styles.textField, className)}>
      <div className={styles.labelBox}>{renderLabel}</div>
      <div className={styles.inputBox}>{renderInput}</div>
    </div>
  )
}

export default TextField
